// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-jsx": () => import("./../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-csr-jsx": () => import("./../src/pages/csr.jsx" /* webpackChunkName: "component---src-pages-csr-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-useful-links-jsx": () => import("./../src/pages/useful-links.jsx" /* webpackChunkName: "component---src-pages-useful-links-jsx" */),
  "component---src-pages-values-jsx": () => import("./../src/pages/values.jsx" /* webpackChunkName: "component---src-pages-values-jsx" */)
}

